import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },

  //!----------------About --------
  {
    id: "aboutPage",
    title: "About Us",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/about",
  },
  //!----------------Home --------

  {
    id: "homepage",
    title: "Home Banner",
    type: "item",
    icon: <Icon.Circle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home-page",
  },

  //!----------------Projects --------
  {
    id: "projects",
    title: "Projects",
    type: "collapse",
    icon: <Icon.Box size={15} />,
    children: [
      {
        id: "projectsList",
        title: "Projects",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/project/list",
      },
      {
        id: "propertyType",
        title: "Property Type",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/property-type/list",
      },
      // {
      //   id: "keyDetailsList",
      //   title: "Key Details",
      //   type: "item",
      //   icon: <Icon.List size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/key-details/list",
      // },
      {
        id: "amenitiesList",
        title: "Amenities",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/amenities/list",
      },
    ],
  },

  //!----------------Zone--------
  {
    id: "zoneList",
    title: "Zone",
    type: "item",
    icon: <Icon.Globe size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/zone/list",
  },

  //!----------------Location--------
  {
    id: "locationList",
    title: "Locations",
    type: "item",
    icon: <Icon.MapPin size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/location/list",
  },

  //!--------------Developers-------
  {
    id: "developerList",
    title: "Developers",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/developer/list",
  },

  // !---------------- Testimonials--------
  {
    id: "testimoniallist",
    title: "Testimonials",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/testimonial/list",
  },

  //!----------------Blogs--------
  {
    id: "blogList",
    title: "Blog",
    type: "item",
    icon: <Icon.AlertTriangle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/blog/list",
  },

  //!--------------faqs-------
  {
    id: "faqsList",
    title: "FAQ's",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faqs/list",
  },

  //!----------------contact list--------
  {
    id: "ContactUsFormList",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Phone size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/contact-list",
  },

  //!----------------pages list--------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Link size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages/list",
  },

  //!----------------Website Pages --------
  {
    id: "staticList",
    title: "Website Pages",
    type: "collapse",
    icon: <Icon.List size={15} />,
    children: [
      {
        id: "about",
        title: "About",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about",
      },
      {
        id: "investment",
        title: "Investment",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/investment",
      },
      {
        id: "ahmadabad",
        title: "Ahmadabad ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ahmadabad",
      },
      {
        id: "dholera",
        title: "Dholera SIR",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/dholera",
      },
    ],
  },

  //!----------------subscribers List--------
  // {
  //   id: "SubscribersList",
  //   title: "Subscribers",
  //   type: "item",
  //   icon: <Icon.Users size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/subscribers-list",
  // },

  //!---------------Users------
  // {
  //   id: "users",
  //   title: "Registered Users",
  //   type: "item",
  //   icon: <Icon.Users size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/users-list",
  // },

  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
];

export default navigationConfig;
